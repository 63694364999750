<template>
  <div class="description">
    <div class="layout__container--width">
      <div class="solution__layout">
        <div class="solution__text-group">
          <div v-if="cardData.title" class="solution__text-title">{{cardData.title}}</div>
          <div class="solution__group" v-for="item in cardData.text" :key="item.id">
            <div class="solution__icon"><img :src="item.logo" alt=""></div>
            <div class="solution__content">
              <div class="solution__title">
                {{item.title}}
              </div>
              <div class="solution__ul" >
                <div class="solution__li" style="white-space: pre-wrap" v-for="i in item.content" :key="i.list"><span class="layout__decorator" />{{i.list}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="solution__logo">
          <img :src="cardData.img" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    cardData:{ type: Object, default: () => ({}) }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";

.description {
  padding-bottom: 65px;
}
.solution__layout {
  display: flex;
  justify-content: space-between;
}

.solution__text-group {
  flex: 1;
}
.solution__text-title {
  margin-bottom:40px;
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0d253e;
  line-height: 45px;
  letter-spacing: 2px;
}
.solution__logo {
  width: 545px;
  height: auto;
  margin-left:15px ;
}

.solution__logo img {
  width: 100%;
  height: auto;
}

.solution__group {
  display: flex;
  margin-bottom: 18px;
}

.solution__icon {
  margin-right: 16px;
  width: 78px;
  height: 85px;
}

.solution__content {
  flex: 1;
}

.solution__title {
  margin-bottom: 5px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #2E4068;
  line-height: 24px;
}

.solution__li {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #102363;
  line-height: 26px;
}
.solution__li .layout__decorator {
  margin-top: 10px;
  margin-right: 5px;
  float: left;
}

</style>
