<template>
  <div class="content-container">
    <div v-if="!isExtend">
       <slot name="not-extend" />
    </div>
    <div v-else>
       <slot name="extend" />
    </div>
    <div class="footer" v-if="isShowFooter">
      <div v-if="!isExtend" class="footer__ellipsis">...</div>
      <div v-if="isExtend" class="footer__placeholder"></div>
      <div v-if="!isExtend" class="footer__extend" @click="()=> isExtend = true"><span class="check__all">查看全部</span><img src="/assets/solution/arrow@3x.png" alt="" class="arrow"></div>
      <div v-if="isExtend" class="footer__not__extend" @click="()=>isExtend = false"><span class="check__all">收起</span><img src="/assets/solution/arrow@3x.png" alt="" class="arrow"></div>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'

export default {
  props:{
    isShowFooter: { type:Boolean, default: true },
  },
  setup () {
     let isExtend = ref(false)
     return {
      isExtend
     }
  }
}
</script>
<style lang="scss" scoped>
.content-container {
  width: 985px;
  padding-bottom: 10px;
  border-bottom:2px solid #D8D8D8; ;
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  margin-top: 15px;
  height: 30px;
}
.footer__ellipsis {
  height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0D1A26;
  line-height: 33px;
}
.footer__extend, .footer__not__extend {
  cursor: pointer;
}
.check__all {
  width: 48px;
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1E88F5;
  line-height: 17px;
}
.arrow {
  width: 15px;
  height: 10px;
  vertical-align: middle;
  margin-left: 3px;
}
</style>
