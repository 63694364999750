<template>
  <div class="description">
    <div class="layout__container--width">
      <div class="solution__layout">
        <div class="solution__logo">
          <slot name="img" />
        </div>
        <div class="solution__text-group">
          <div class="solution__text-title">
            <slot name="title" />
          </div>
          <div class="solution__content">
            <slot name="content" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" scoped>
.description {
  padding-bottom: 65px;
}
.solution__layout {
  display: flex;
  justify-content: space-between;
}

.solution__text-group {
  flex: 1;
}
.solution__text-title {
  margin: 50px auto 32px;
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000c42;
  line-height: 28px;
}

.solution__logo {
  width: 550px;
  height: auto;
  margin-right: 214px;
}

.solution__logo img {
  width: 100%;
  height: auto;
}

.solution__group {
  display: flex;
  margin-bottom: 18px;
}

.solution__icon {
  margin-right: 16px;
  width: 78px;
  height: 85px;
}
</style>
