<template>
  <div class="card">
    <div class="card__logo" :style="{ width: logo.width, height: logo.height, marginRight: logo.marginRight }">
      <slot name="logo" />
    </div>
    <div class="card__content-container">
      <div class="card__title">
        <slot name="title" />
      </div>
      <div class="card__content">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  props: ['logo'],
  setup (props) {
    const { logo = {}} = props
    let { size, width, height, marginRight } = logo
    if (size) {
      width = size
      height = size
    }
    return {
      logo: { width, height, marginRight }
    }
  }
})
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  margin-top: 25px;
}
.card__title {
  margin-bottom: 5px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000C42;
  line-height: 28px;
}
</style>