<template>
    <div class="flow">
      <div class="flow__items">
        <div
          v-for="(item,index) in flowData"
          class="flow__item"
          :class="[getActiveItemClass(index)]"
          @mouseenter="enterItem(index)"
        >
          <div class="flow__title"><span v-if="item.titleTop">{{item.titleTop}}<br /></span> {{item.title}}</div>
          <div class="flow__content" v-for="content in item.content">
            <div class="flow__content-title">{{content.contentTitle}}</div>
            <div class="flow__content-text">{{content.contentText}}</div>
          </div>
          <div class="flow__footer">
            <div class="flow__footer-text">了解一下</div>
          </div>
        </div>     
      </div>
    </div>
</template>

<script>
import { ref } from 'vue'
export default {
  props:{
    flowData:{ type: Array, default: () => [] }
  },
  setup () {
    const currentIndex = ref(1)
    const changeIndex = (index) => {
      currentIndex.value = index;
    }
    const getActiveItemClass = (index) => {
      return index === currentIndex.value ? 'flow__item--active' : ''
    }
    const enterItem = (index) => {
      changeIndex(index)
    }

    return {
      getActiveItemClass,
      enterItem
    }
  },
}
</script>

<style lang="scss" scoped>
.flow {
  margin-top: 76px;
  margin-bottom: 53px;
}

.flow__items {
  display: flex;
  justify-content: space-between;
}

.flow__item {
  position: relative;
  height: 706px;
  padding: 50px 35px 60px 50px;
  cursor: pointer;
  text-align: center;
  flex: 1;
  border-top: 4px solid transparent;
}
.flow__item--active {
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 0px 13px 3px rgba(0, 0, 0, 0.08);
  border-top: 4px solid #3B85FE;
}
.flow__title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000C42;
  line-height: 33px;
}

.flow__content {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  line-height: 24px;
  text-align: left;
}
.flow__content-title {
  margin-top: 35px;
  margin-bottom: 6px;
  font-weight: 500;
  color: #1E88F5;
}
.flow__content-text {
  font-weight: 400;
  color: #000C42;
}
.flow__footer {
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 271px;
  height: 64px;
  background: #1E88F5;
  box-shadow: 0px 9px 36px 0px rgba(30, 136, 245, 0.24);
  border-radius: 5px 0px 5px 0px;

  cursor: pointer;
}
.flow__footer-text {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 33px;
  letter-spacing: 1px;
}

</style>
