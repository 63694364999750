<template>
  <HostLayoutContainer>
    <HostLayoutItem>
      <TitleWithUnderline :title="title" id="success-case"/>
      <div class="case1">
        <Card9 :cardData="cardData1" />
      </div>
      <div class="case2">
        <Card9 :cardData="cardData2" />
      </div>
      <div class="case3">
        <Card9 :cardData="cardData3" />
      </div>
    </HostLayoutItem>
  </HostLayoutContainer>
</template>
<script>
import TitleWithUnderline from '@/components/title/title-with-underline.vue'
import Card9 from '@/components/cards/card9'
export default {
  components: {
    TitleWithUnderline,
    Card9
  },
  setup () {
    return {
      title: { marginBottom: '96px', marginTop: '112px', text: '客户成功案例' },
      cardData1: {
        img: '/assets/solution/case_1@3x.png',
        title:'游戏公司',
        text: [
          {
            id: '1',
            logo: '/assets/solution/case-logo_1@3x.png',
            title: '客户痛点',
            content: [
              { list: '由于跨国游戏服务带来的游戏延时高、登录困难、经常掉线等游戏体验问题' },
              { list: '游戏发行和部署涉及到混合云网络架构，整体搭建非常复杂，技术难度高' },
              { list: '跨区域、跨国际网络访问延期无法满足在线游戏业务实时通讯，极大影响使用体验' },
              { list: '如何提供安全的方式来规避合规风险，如何缩短跨境网络交付的时间和提高效率是比较难的问题' }
            ]
          },
          {
            id: '2',
            logo: '/assets/solution/case-logo_2@3x.png',
            title: '解决方案',
            content: [
              { list: '通过内容分发网络CDN实现游戏静态内容加速，加速游戏更新、游戏下载包加速' },
              { list: '通过云上负载均衡产品，调度全服游戏流量，实现高并发、高稳定性游戏体验' },
              { list: '通过云存储存放游戏静态文件（例如游戏更新包、下载包等），根据实际情况部署，方便进行游戏版本的更新' },
              { list: '移动端“最后一公里加速”使用IOS/Android SDK继承移动加速产品，给游戏出海加速提供完整的解决方案' }
            ]
          },
          {
            id: '3',
            logo: '/assets/solution/case-logo_3@3x.png',
            title: '客户收益',
            content: [
              { list: '缩短了开发周期，在激烈竞争中业务得以快速上线' },
              { list: '降低了一次性投入，仅需采购部分服务器搭建自己的业务逻辑' },
              { list: '复杂的视频业务接入海外云，轻资产出发获得启步优势，获得商业主动权' },
              { list: '借助云上成熟稳定的资源，让自己获得和大厂一样的业务质量和保障' }
            ]
          }
        ]
      },
      cardData2: {
        img: '/assets/solution/case_2@3x.png',
        title: '大型汽车制造商',
        text: [
          {
            id: '1',
            logo: '/assets/solution/case-logo_1@3x.png',
            title: '客户痛点',
            content: [
              { list: '原项目关停，需要有新的平台来承载其现网业务' },
              { list: '海外业务数据可视化程度不够，客户的用户方无法准确得知业务情况及走势（监控海外车辆运行情况，包括每日车辆全国热力展示，车机操作相关指标，销售数据，日活用户，注册用户，每日新增注册用户等信息）需要一套更完善、更全面的数据可视化平台' }]
          },
          {
            id: '2',
            logo: '/assets/solution/case-logo_2@3x.png',
            title: '解决方案',
            content: [
              { list: '以海外云平台作为现网及新业务承载平台，通过“数据批量处理-数据仓库存储并查询—可视化界面展示”的业务逻辑，完成数据从“抽取-清洗&建模-可视化”的过程' },
              { list: '技术选型 \n（1）存储工具：将数据从本地数据中心、其他云厂商复制到海外云存储 \n（2）数据流分析：帮助分析和处理可用于获取见解、生成报表的数据流 \n（3）数据仓库：用于编写查询、数据转换以及提取有价值的内容进行存储 \n（4）虚拟机：用于部署相关定时任务以及数据处理的项目 \n（5）云存储：用来存储数据文件以及其他相关工程依赖的包 \n（6）BI：用于展示实时数据分析的报表'}
            ]
          },
          {
            id: '3',
            logo: '/assets/solution/case-logo_3@3x.png',
            title: '客户收益',
            content: [
              { list: '现网业务平滑过渡，业务不中断' },
              { list: '提升业务数据可视化程度，帮助客户更快更准确做出业务决策' }
            ]
          }
        ]
      },
      cardData3: {
        img: '/assets/solution/case_3@3x.png',
        title: '互联网社交公司',
        text: [
          {
            id: '1',
            logo: '/assets/solution/case-logo_1@3x.png',
            title: '客户痛点',
            content: [
              { list: '现有基础设施资源集中在国内IDC，而海外用户量快速增长，东南亚、日本、北美、欧洲均有大量用户群，需要本地覆盖' },
              { list: '直播等新业务上线，对带宽及延时敏感，传统的CDN无法带来很好的海外用户体验' },
              { list: ' 现有IT运维团队为IDC/私有云背景，对公有云特别是IAAS资源运维不熟悉，使用经验不足' }
            ]
          },
          {
            id: '2',
            logo: '/assets/solution/case-logo_2@3x.png',
            title: '解决方案',
            content: [
              { list: '协助客户成功部署海外服务节点，底层使用海外公有云资源，涉及到VM、负载均衡、CDN等产品' },
              { list: '采用跨境专线的方式打通国内IDC和海外网络，通过VPC打通海外区域，实现全球资源网络统一' }
            ]
          },
          {
            id: '3',
            logo: '/assets/solution/case-logo_3@3x.png',
            title: '客户收益',
            content: [
              { list: '通过海外公有云的全球基础网络结合CDN，完成直播的流量快速分发' },
              { list: '通过云运维监控报警机制，及时发现并快速定位故障点，大幅提高服务可用时间' },
              { list: '架构、系统性能优化，提高对基础资源的利用率，减少基础资源用云成本，减少本地网络复杂管理' }
            ]
          }
        ]
      }
    }
  }
}
</script>
