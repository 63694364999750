<template>
  <div class="description">
    <div class="layout__container--width">
      <div class="solution__layout">
        <div class="solution__text-group">
          <div v-if="cardData.title" class="solution__text-title">{{cardData.title}}</div>
          <div class="solution__group" v-for="item in cardData.text" :key="item.id">
            <div class="solution__content">
              <div class="solution__title">{{item.title}}</div>
              <div class="solution__li" style="white-space: pre-wrap">{{item.content}}</div>
            </div>
          </div>
        </div>
        <div class="solution__logo">
          <img :src="cardData.img" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    cardData:{ type: Object, default: () => ({}) }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";

.description {
  padding-bottom: 65px;
}
.solution__layout {
  display: flex;
  justify-content: space-between;
}

.solution__text-group {
  flex: 1;
}
.solution__text-title {
  margin-bottom: 32px;
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000C42;
  line-height: 28px;
}

.solution__logo {
  width: 480px;
  height: auto;
  margin-left:99px ;
}

.solution__logo img {
  width: 100%;
  height: auto;
}

.solution__group {
  display: flex;
  margin-bottom: 18px;
}

.solution__icon {
  margin-right: 16px;
  width: 78px;
  height: 85px;
}

.solution__content {
  flex: 1;
}

.solution__title {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #1E88F5;
  line-height: 28px;;
}

.solution__li {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #102363;
  line-height: 26px;
}

</style>
