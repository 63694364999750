<template>
  <HostLayoutContainer>
    <HostLayoutItem>
      <TitleWithUnderline :title="title" id="solution-cloud-platform"/>
      <div class="core__function">
        <Card10 :cardData="card10Data" />
      </div>
      <div class="customer__value">
        <Card11>
          <template #img
            ><img src="/assets/solution/cloud-platform_2@3x.png" alt=""
          /></template>
          <template #title>客户价值——新一代产品化融合云平台</template>
          <template #content>
            <div class="content-group">
              <div v-for="item in card11Data" class="content-item">
                <div class="content-title">{{ item.title }}</div>
                <div class="content-ul">
                  <div class="content-li" v-for="i in item.text" :key="i.list">
                    <span class="layout__decorator" />{{ i.list }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Card11>
      </div>
    </HostLayoutItem>
  </HostLayoutContainer>
</template>
<script>
import TitleWithUnderline from '@/components/title/title-with-underline.vue'
import Card10 from '@/components/cards/card10'
import Card11 from '@/components/cards/card11'

export default {
  components: {
    TitleWithUnderline,
    Card10,
    Card11
  },
  setup () {
    return {
      title: { marginBottom: '156px', marginTop: '105px', text: '云管理平台解决方案' },
      card10Data: {
        img: '/assets/solution/cloud-platform_1@3x.png',
        title: '核心功能——全面解决多云/异构问题',
        text: [
          {
            id: '1',
            title: '成本管理和优化',
            content: '在一个地方查看所有云支出；数据可视化方式，全面、多维度分析成本；预算管理，防止超支；发现闲置、低负载等资源，减少成本浪费，提高资源使用率'
          },
          {
            id: '2',
            title: '统一资源管理',
            content: '一站式自服务采买所有资源；同配置，全网比价；无需在多个不同的云控制台反复切换；资源的统一管理及批量操作'
          },
          {
            id: '3',
            title: '统一监控报警',
            content: '统一查看监控数据；统一设置报警策略；通知渠道支持飞书、钉钉、企业微信等；科技感地图监控大屏，一览全局资源情况'
          },
          {
            id: '4',
            title: '安全与合规',
            content: '在一个地方创建及管理所有云平台的子账号，并分配权限；通过云联壹云控制台可一键跳转免密登录公有云控制台；员工离职，自动注销对应的公有云子账号'
          },
        ]
      },
      card11Data: [
        {
          id: '1',
          title: '省钱',
          text: [
            { list: '算好账' },
            { list: '摊好钱' },
            { list: '节成本' }
          ]
        },
        {
          id: '2',
          title: '省人省事',
          text: [
            { list: '一站式采买' },
            { list: '批量操作' },
            { list: '一次配置，全网生效' },
            { list: '统一获取账单' }
          ]
        },
        {
          id: '3',
          title: '安全',
          text: [
            { list: '统一认证' },
            { list: '统一授权' },
            { list: '免密登录' },
          ]
        }]
    }
  }
}
</script>
<style lang="scss" scoped>
@import "src/styles/index.scss";

.content-title {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #1e88f5;
  line-height: 28px;
}
.content-group {
  display: flex;
  flex-wrap: wrap;
}
.content-item {
  width: 50%;
}
.content-li {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000c42;
  line-height: 24px;
}
</style>