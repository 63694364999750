<template>
  <Banner />
  <SolutionMixture />
  <SolutionModernization />
  <SolutionCloudPlaform/>
  <SuccessCase/>
  <Footer />
  <CopyRight/>
</template>

<script>
import Banner from '@/components/banner.vue'
import SolutionMixture from '@/components/solution/solution-mixture/index.vue'
import SolutionModernization from '@/components/solution/solution-modernization/index.vue'
import SolutionCloudPlaform from '@/components/solution/solution-cloud-plaform/index.vue'
import SuccessCase from '@/components/solution/success-case/index.vue'
import Footer from '@/components/footer.vue'
import CopyRight from '@/components/copy-right.vue'
export default {
  name:'Solution',
  components: {
    Banner,
    SolutionMixture,
    SolutionModernization,
    SolutionCloudPlaform,
    SuccessCase,
    CopyRight,
    Footer,
  }
}
</script>
