<template>
  <HostLayoutContainer class="banner" >
    <HostLayoutItem>
       <TitleWithUnderline :title="title" id="solution-mixture"/>
        <MixtureContainer :logo="logo">
          <template #logo><img src="/assets/solution/ty-1@3x.png" alt=""></template>
          <template #title>支持各种设备的Office 应用</template>
          <template #content>
            <MixtureContent>
              <template #not-extend>
                <div class="content__li">在企业上云的热潮中，混合云是规模企业的首选策略。通过公有云和私有云的联合部署业务架构，有效地降低IT成本，获得最佳的效益组合。</div>
                <div class="content__li">混合云在公有云+私有云联合部署的模式下，通过使用公有云的计算、存储、网络、大数据资源，降低业务运维成本，突破私有云的硬件限制。在业务规模不断</div>
              </template>
              <template #extend>
                <div class="content__li">在企业上云的热潮中，混合云是规模企业的首选策略。通过公有云和私有云的联合部署业务架构，有效地降低IT成本，获得最佳的效益组合。</div>
                <div class="content__li">混合云在公有云+私有云联合部署的模式下，通过使用公有云的计算、存储、网络、大数据资源，降低业务运维成本，突破私有云的硬件限制。在业务规模不断增长的过程中，利用公有云的可扩展性，可以随时扩展计算能力；利用公有云冗余备份策略，提升业务可用性；选用合适的计费方式，实现最小成本的需求。 出于行业规范、数据安全等原因，企业把基础架构全部迁移到公有云上并不现实，而混合云同时兼顾了敏捷性和安全性，成为了企业的最佳选择。</div>
              </template>
            </MixtureContent>
          </template>
        </MixtureContainer>
        <MixtureContainer :logo="logo">
          <template #logo><img src="/assets/solution/ty-2@3x.png" alt=""></template>
          <template #title>解决方案概述</template>
          <template #content>
            <MixtureContent :isShowFooter="false">
              <template #not-extend>
                <div class="content__li" style="margin-bottom:30px">客户核心业务部署在自有数据中心，外围或业务前端部署在公有云平台，实现公有云与私有云、数据中心之间的网络直连与混合组网，构建混合云架构。</div>
              </template>
            </MixtureContent>
          </template>
        </MixtureContainer>
        <MixtureContainer :logo="logo">
          <template #logo><img src="/assets/solution/ty-3@3x.png" alt=""></template>
          <template #title>架构特点及设计</template>
          <template #content>
            <MixtureContent>
              <template #not-extend>
                <div class="content__li"><span class="layout__decorator" /> 通过部署公有云负载均衡服务、存储服务和应用程序计算资源，充分利用公有云的可扩展性、静态资源缓存等特点，保证平台高并发和业务突增的要求。</div>
                <div class="content__li"><span class="layout__decorator" /> 通过私有云存储用户的隐私数据并配合鉴权配置，保证数据的安全，以满足合规性要求。</div>
              </template>
              <template #extend>
                <div class="content__li"><span class="layout__decorator" /> 通过部署公有云负载均衡服务、存储服务和应用程序计算资源，充分利用公有云的可扩展性、静态资源缓存等特点，保证平台高并发和业务突增的要求。</div>
                <div class="content__li"><span class="layout__decorator" /> 通过私有云存储用户的隐私数据并配合鉴权配置，保证数据的安全，以满足合规性要求。</div>
                <div class="content__li"><span class="layout__decorator" /> 在网络层面通过IPsec-VPN可建立专有网络VPC（Virtual Private Cloud）与本地数据中心间的VPN连接。</div>
                <div class="content__li-sub-title">架构设计</div>
                <div class="content__li-img">
                  <img src="/assets/solution/architecture-design@3x.png" alt="">
                </div>
              </template>
            </MixtureContent>
          </template>
        </MixtureContainer>
        <MixtureContainer :logo="logo">
          <template #logo><img src="/assets/solution/ty-4@3x.png" alt=""></template>
          <template #title>方案效果</template>
          <template #content>
            <MixtureContent>
              <template #not-extend>
                <div class="content__li"><span class="layout__decorator" />现代化改造业务结构：在本地数据中心中构建和部署云原生应用。将本地虚拟化应用整合到具有最佳性价比且已连接云的超融合基础结构上。</div>
                <div class="content__li"><span class="layout__decorator" />将计算和存储扩展部署到边缘：将业务的计算、存储等场景的业务需求，在边缘运行机器学习和高级分析，以获取实时见解。</div>
              </template>
              <template #extend>
                <div class="content__li"><span class="layout__decorator" />现代化改造业务结构：在本地数据中心中构建和部署云原生应用。将本地虚拟化应用整合到具有最佳性价比且已连接云的超融合基础结构上。</div>
                <div class="content__li"><span class="layout__decorator" />将计算和存储扩展部署到边缘：将业务的计算、存储等场景的业务需求，在边缘运行机器学习和高级分析，以获取实时见解。</div>
                <div class="content__li"><span class="layout__decorator" />混合云方式部署，通过一个管控平台为用户提供公有云、私有云架构一致的云平台使用体验，降低部署成本，提升运维管理效率 。</div>
                <div class="content__li"><span class="layout__decorator" />混合云弹性缩放、自动化的性能，充分利用已有IT资源，实现业务花费最小化，并且减轻运维人员在程序升级时的工作量。</div>
                <div class="content__li"><span class="layout__decorator" />通过统一的管控平台实现不同业务架构资源的整合，实现数据安全、安全合规等要求。</div>
              </template>
            </MixtureContent>
          </template>
        </MixtureContainer>
    </HostLayoutItem>
  </HostLayoutContainer>
</template>
<script>
import TitleWithUnderline from '@/components/title/title-with-underline.vue'
import MixtureContainer from '@/components/solution/solution-mixture/container.vue'
import MixtureContent from '@/components/solution/solution-mixture/content.vue'
export default {
  components:{
    TitleWithUnderline,
    MixtureContainer,
    MixtureContent,
  },
  setup() {
    return {
      title: { marginBottom: '85px', marginTop: '75px', text: '混合云构建解决方案' },
      logo: { size: '87px', marginRight:'45px'},
    }
  }
}
</script>
<style lang="scss" scoped>
@import "src/styles/index.scss";
.banner {
  background-color: #fff;
}
.content__li {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000C42;
  line-height: 24px;
}
.content__li-sub-title {
  margin: 15px 0;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000C42;
  line-height: 28px;
}
.content__li-img {
  width: 322px;
  height: 242px;
}
</style>